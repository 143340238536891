import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import logo from './MagnaLogo.png';
//import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import UserInfo from '../../ts/interfaces/app/userinfo.interface';
import Grid from '@mui/material/Grid';

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        white: true;
    }
}

export default function Header({ pageWidth, /*userInfo,*/ isMobile }: { pageWidth: number; /*userInfo: UserInfo;*/ isMobile: boolean }) {
    let navigate = useNavigate();
    //const [width] = useWindowSize();

    if (!isMobile) {
        return (
            <Box sx={{ marginBottom: 2 }}>
                <AppBar position='static'>
                    <Toolbar variant='dense'>
                        <Container>
                            <Box display='flex' alignItems='left' justifyContent='left' width={pageWidth} sx={{ marginLeft: 'auto' }}>
                                <img style={{ maxHeight: 35, marginTop: -3 }} src={logo} alt='logo' />
                                &emsp;&emsp;&emsp;
                                <Typography style={{ marginTop: 2 }} variant='h5' color='inherit' component='div'>
                                    <strong>Drive Employee Submissions Dashboard</strong>
                                </Typography>
                                &emsp;&emsp;&emsp;
                                <Button
                                    variant='text'
                                    color={'white'}
                                    sx={{ minWidth: 100 }}
                                    onClick={() => {
                                        navigate('/');
                                    }}
                                >
                                    <strong>Home</strong>
                                </Button>
                                <Button
                                    variant='text'
                                    color={'white'}
                                    sx={{ minWidth: 100 }}
                                    onClick={() => {
                                        navigate('/mysubmissions');
                                    }}
                                >
                                    <strong>My Submissions</strong>
                                </Button>
                            </Box>
                        </Container>
                    </Toolbar>
                </AppBar>
            </Box>
        );
    } else {
        return (
            <Box sx={{ marginBottom: 2 }}>
                <AppBar position='static'>
                    <Toolbar variant='dense'>
                        <Container>
                            <Grid container alignItems='center' justifyContent='center' style={{ marginTop: '3vh', marginBottom: '3vh' }}>
                                <Grid item xs={6}>
                                    <Box display='flex' alignItems='left' justifyContent='left' width={pageWidth} sx={{ marginLeft: 'auto' }}>
                                        <img style={{ maxHeight: 35, marginTop: -3 }} src={logo} alt='logo' />
                                        &emsp;&emsp;&emsp;
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Toolbar>
                </AppBar>
            </Box>
        );
    }
}
