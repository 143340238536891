import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useLocation } from 'react-router-dom';

function capitalizeFirstLetter(s:string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

export default function AppBreadcrumbs() {

    const location = useLocation();
    const navigate = useNavigate();
    const [breadcrumbs, setBreadcrumbs] = React.useState<{ text: string; path: string }[]>([]);

    React.useEffect(() => {

        let pathname = location.pathname;
        let splitPathname = pathname.split('/');

        let homeLink = {
            "text": "Home",
            "path": "/"
        }

        let otherLinks: { text: string; path: string }[] = [];
        let currentBuiltPath = "";
        splitPathname.forEach((path) => {
            if (path !== '' && path != null) {

                currentBuiltPath += `/${path}`;

                otherLinks.push(
                    {
                        "text": capitalizeFirstLetter(path),
                        "path": currentBuiltPath
                    }
                )
            }

        });

        if (otherLinks.length > 0) {
            setBreadcrumbs([homeLink, ...otherLinks]);
        } else {
            setBreadcrumbs([homeLink]);
        }

    }, [location]);

    return (
        <Stack sx={{ paddingLeft: 2 }} spacing={2}>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs.map((breadcrumb, index) => {
                    return (
                        <Link underline="hover" key={index} color="inherit" onClick={() => {
                            navigate(breadcrumb.path);
                        }}>
                            <Typography variant='h5' component='h2' fontStyle='oblique'>
                                <strong>{breadcrumb.text}</strong>
                            </Typography>
                        </Link>
                    )
                })}
            </Breadcrumbs>
        </Stack>
    );
}
