// all external packages brought
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';

// ts interfaces
import submission from '../../ts/interfaces/MySubmissions/submission.interface';
import { CardMedia, IconButton, Modal, Typography } from '@mui/material';

import statuses from '../../constants/statuses.json';

function DetailsModal({
    pageWidth,
    displayModal,
    setDisplayModal,
    submission,
}: {
    pageWidth: number;
    displayModal: boolean;
    setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
    submission: submission;
}) {
    const [dateStr, setDateStr] = useState<string>('');

    useEffect(() => {
        let date = new Date(submission.Date);
        setDateStr(date.toDateString());
    }, [submission]);

    return (
        <div style={{ maxWidth: pageWidth, margin: 'auto' }}>
            <Modal open={displayModal} sx={{ height: 'auto', marginY: '5vh', marginX: '5vw', paddingBottom: '5vw', overflow: 'scroll' }}>
                <Card sx={{ paddingX: '2vw' }}>
                    <Grid container justifyContent={'flex-end'}>
                        <IconButton
                            onClick={() => {
                                setDisplayModal(false);
                            }}
                            sx={{ marginRight: '-2vw' }}
                        >
                            <CloseIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                    </Grid>

                    <Grid container justifyContent={'center'} spacing={1}>
                        <Grid item xs={12}>
                            <Typography textAlign={'center'} sx={{ fontSize: 25, marginBottom: '5vh' }}>
                                <strong>Submission Details:</strong>
                            </Typography>
                        </Grid>

                        {submission.BeforePic !== '' && (
                            <Grid item xs={12}>
                                <Card sx={{ textAlign: 'center', marginTop: '2vh' }}>
                                    <Typography sx={{ fontSize: 25 }}>
                                        <strong>Before Picture</strong>
                                    </Typography>
                                    <CardMedia height={'900px'} component='img' image={`data:image/png;base64,${submission.BeforePic}`} />
                                </Card>
                            </Grid>
                        )}

                        {submission.AfterPic !== '' && (
                            <Grid item xs={12}>
                                <Card sx={{ textAlign: 'center', marginTop: '2vh' }}>
                                    <Typography sx={{ fontSize: 25 }}>
                                        <strong>After Picture</strong>
                                    </Typography>
                                    <CardMedia height={'900px'} component='img' image={`data:image/png;base64,${submission.AfterPic}`} />
                                </Card>
                            </Grid>
                        )}

                        <Grid item xs={12} sx={{ textAlign: 'flex-start', marginTop: '5vh' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Date: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${dateStr}`}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Name: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${submission.Name}`}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Department: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${submission.Dept}`}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Shift: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${submission.Shift}`}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Benefit: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${submission.Benefit}`}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Change: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${submission.Change}`}</Typography>
                        </Grid>

                        {submission.Status === statuses.Denied.text && (
                            <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                                <Typography sx={{ fontSize: 25 }}>
                                    <strong>Denial justification: </strong>
                                </Typography>
                                <Typography sx={{ fontSize: 20 }}>{`${submission.ApproverJustification}`}</Typography>
                            </Grid>
                        )}

                        <Grid item xs={12} sx={{ paddingBottom: '5vh' }} />
                    </Grid>
                </Card>
            </Modal>
        </div>
    );
}

export default DetailsModal;
