import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridSelectionModel, GridRowParams } from '@mui/x-data-grid';
import { Box, Chip, InputAdornment, Stack, TextField } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

function TableWithSearch({
    height,
    DataArr,
    TableCols,
    selModel,
    setSelModel,
}: {
    height: string;
    DataArr: any[];
    TableCols: GridColDef[];
    selModel: GridSelectionModel | undefined;
    setSelModel: React.Dispatch<React.SetStateAction<GridSelectionModel | undefined>>;
}) {
    const [activeArr, setActiveArr] = useState<any[]>([]);
    const [filters, setFilters] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [firstRender, setFirstRender] = useState(true);
    const [reset, setReset] = useState(false);

    const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    function addFilter() {
        let filts = [...filters, searchQuery];
        setFilters(filts);

        let rows = [...activeArr];
        handleSearch(rows, filts);
    }

    function handleSearch(arr: any[], filts: string[]) {
        setSearchQuery('');

        arr.forEach((ind) => {
            ind._Rank_ = 0;
        });

        if (filts.length > 0) {
            filts.forEach((filter) => {
                arr.forEach((row: any) => {
                    for (const [key, value] of Object.entries(row)) {
                        if (key !== 'UID') {
                            if (String(value).toLowerCase().includes(filter.toLowerCase())) row._Rank_ += 10;
                        }
                    }
                });
            });
        }

        arr.sort((a: any, b: any) => (a._Rank_ > b._Rank_ ? -1 : 1));

        setActiveArr(arr);
    }

    function handleRemoveFilter(filter: string) {
        let filts = [...filters];
        filts.splice(
            filts.findIndex((ind) => ind === filter),
            1
        );
        if (filts.length < 1) {
            //state management needs this because ranks are not replaced
            activeArr.forEach((ind) => {
                ind._Rank_ = 0;
            });
            setReset(true);
        } else {
            let rows = [...activeArr];
            handleSearch(rows, filts);
        }
        setFilters(filts);
    }

    useEffect(() => {
        if (!firstRender) return;
        setActiveArr(DataArr);
        setFirstRender(false);
    }, [firstRender, DataArr]);

    useEffect(() => {
        let temp = [...DataArr];
        handleSearch(temp, filters);
    }, [DataArr, filters]);

    useEffect(() => {
        if (!reset) return;
        setActiveArr(DataArr);
        setReset(false);
    }, [reset, DataArr]);

    return (
        <Box
            sx={{
                height: height,
                margin: 'auto',
                flex: 1,
                '& .highlighted': {
                    backgroundColor: '#b9d5ff91',
                },
            }}
        >
            <Stack direction='row' spacing={1}>
                <TextField
                    id='input-with-icon-textfield'
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    label='Search'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position='start'
                                onClick={() => {
                                    addFilter();
                                    setSearchQuery('');
                                }}
                            >
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant='standard'
                    onKeyPress={(ev: React.KeyboardEvent) => {
                        if (ev.key === 'Enter') {
                            addFilter();
                            setSearchQuery('');
                        }
                    }}
                />
                <Chip label='Filters:' />
                <div style={{ flexWrap: 'wrap' }}>
                    {filters.map((el: any, index: number) => {
                        return <Chip label={el} key={index} onDelete={() => handleRemoveFilter(el)} sx={{ marginBottom: 2, marginRight: 1 }} />;
                    })}
                </div>
            </Stack>

            <DataGrid
                density='compact'
                checkboxSelection={false}
                components={{
                    //Toolbar: GridToolbar,
                    //NoRowsOverlay: CustomNoRowsOverlay,
                }}
                getRowId={(row) => row.UID}
                rows={activeArr}
                columns={TableCols}
                getRowHeight={() => 'auto'}
                getRowClassName={(params: GridRowParams) => {
                    return params.row._Rank_ >= 10 ? 'highlighted' : '';
                }}
                sx={{ margin: 2 }}
                disableSelectionOnClick
                selectionModel={selModel}
                onSelectionModelChange={(params) => setSelModel(params)}
            />
        </Box>
    );
}

export default TableWithSearch;
