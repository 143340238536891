import ReactDOM from 'react-dom/client';
import App from './App';
import { HashRouter } from 'react-router-dom';

const htmlRoot = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(htmlRoot!); // Typescript has a non-null assertion that you can use when you are sure that the value is never null by adding the ! operator to the end of your statement
root.render(
    <HashRouter>
        <App />
    </HashRouter>
);
