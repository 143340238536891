import Typography from '@mui/material/Typography';

function CardHeader({ title, subtitle }: { title: string; subtitle: string; }) {

    return (
        <>
            <Typography sx={{paddingLeft: 2, paddingTop: 2}} variant='h5' component='h2'>
                <strong>{title}</strong>
            </Typography>
            <Typography sx={{ paddingLeft: 2, paddingBottom: 2 }} fontStyle='italic' variant='body2' component='h2'>
                {subtitle}
            </Typography>
        </>
    );
}

export default CardHeader;
