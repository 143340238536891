// all external packages brought
import React, { useCallback, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { CardContent, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import * as _ from "lodash";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InfoIcon from "@mui/icons-material/Info";

import GetInfoModal from "../components/MySubmissions/GetInfoModal";
import DetailsModal from "../components/MySubmissions/DetailsModal";
import TableWithSearch from "../components/MySubmissions/TableWithSearch";
import statuses from "../constants/statuses.json";

// ts interfaces
import UserInfo from "../ts/interfaces/app/userinfo.interface";
import Employee from "../ts/interfaces/MySubmissions/employee.interface";
import submission from "../ts/interfaces/MySubmissions/submission.interface";
import Status from "../ts/interfaces/MySubmissions/status.interface";

import {
  getUserSubmissions,
  getPics,
  getUserBigFinds,
  getBigFindPic,
} from "../services/Api";
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import BigFindDetailsModal from "../components/MySubmissions/BigFindDetailsModal";
import BigFind from "../ts/interfaces/MySubmissions/bigfind.interface";

function MySubmissions({
  pageWidth,
  userInfo,
  isMobile,
}: {
  pageWidth: number;
  userInfo: UserInfo;
  isMobile: boolean;
}) {
  const now = new Date();
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    new Date(now.getFullYear(), 0, 1)
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
  );

  const [tab, setTab] = useState(0);
  const [employee, setEmployee] = useState<Employee>({
    lastName: "",
    badgeNum: 0,
    hidNum: 0,
  });
  const [displayGetInfoModal, setDisplayModal] = useState<boolean>(true);
  const [displayDetailsModal, setDisplayDetailsModal] =
    useState<boolean>(false);
  const [displayBigFindDetailsModal, setDisplayBigFindDetailsModal] =
    useState<boolean>(false);
  const [fetchPics, setFetchPics] = useState<boolean>(false);
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
  const [impactArr, setImpactArr] = useState<submission[]>([]);
  const [leanArr, setLeanArr] = useState<submission[]>([]);
  const [bigFindArr, setBigFindArr] = useState<any[]>([]);
  const [activeArr, setActiveArr] = useState<submission[]>([]);
  const [activeCols, setActiveCols] = useState<GridColDef[]>([]);
  const [impactCols, setImpactCols] = useState<GridColDef[]>([]);
  const [leanCols, setLeanCols] = useState<GridColDef[]>([]);
  const [bigFindCols, setBigFindCols] = useState<GridColDef[]>([]);
  const [selModel, setSelModel] = useState<GridSelectionModel>();
  const [selectedSubmission, setSelectedSubmission] = useState<submission>({
    AfterPic: "",
    Approver: "",
    BadgeNumber: "",
    BeforePic: "",
    Benefit: "",
    Change: "",
    Date: "",
    Dept: "",
    Email: "",
    ApproverJustification: "",
    Name: "",
    PicChange: 0,
    Shift: "",
    Status: "",
    Type: "",
    UID: "",
  });
  const [selectedBigFind, setSelectedBigFind] = useState<BigFind>({
    Approver: "",
    BadgeNumber: "",
    Picture: "",
    Summary: "",
    Date: "",
    Dept: "",
    DenialJustification: "",
    Name: "",
    Shift: "",
    Status: "",
    Supervisor: "",
    UID: "",
  });

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    //debugger;
    setTab(newValue);
  };



  useEffect(() => {
    if (loginSuccess) {
      if (!selectedStartDate || !selectedEndDate) return;

      let startDate = selectedStartDate.toISOString();
      let endDate = selectedEndDate.toISOString();

      let name = employee.lastName;

      getUserSubmissions(
        name,
        employee.badgeNum,
        startDate,
        endDate,
        "Impact"
      ).then((ret1: submission[]) => {
        if (employee.badgeNum !== employee.hidNum) {
          getUserSubmissions(
            name,
            employee.hidNum,
            startDate,
            endDate,
            "Impact"
          ).then((ret2: submission[]) => {
            let ret: submission[] = [...ret1, ...ret2];
            ret.forEach((el, ind) => {
              el.BeforePic = "";
              el.AfterPic = "";
              el.PicChange = 0;
              if (!_.isString(el.ApproverJustification))
                el.ApproverJustification = "";
            });

            ret.sort((a: submission, b: submission) =>
              Date.parse(a.Date) > Date.parse(b.Date) ? -1 : 1
            );

            setImpactArr(ret);
          });
        } else {
          let ret: submission[] = [...ret1];
          ret.forEach((el, ind) => {
            el.BeforePic = "";
            el.AfterPic = "";
            el.PicChange = 0;
            if (!_.isString(el.ApproverJustification))
              el.ApproverJustification = "";
          });

          ret.sort((a: submission, b: submission) =>
            Date.parse(a.Date) > Date.parse(b.Date) ? -1 : 1
          );

          setImpactArr(ret);
        }
      });

      getUserSubmissions(
        name,
        employee.badgeNum,
        startDate,
        endDate,
        "Lean"
      ).then((ret1: submission[]) => {
        if (employee.badgeNum !== employee.hidNum) {
          getUserSubmissions(
            name,
            employee.hidNum,
            startDate,
            endDate,
            "Lean"
          ).then((ret2: submission[]) => {
            let ret: submission[] = [...ret1, ...ret2];
            ret.forEach((el, ind) => {
              el.BeforePic = "";
              el.AfterPic = "";
              el.PicChange = 0;
              if (!_.isString(el.ApproverJustification))
                el.ApproverJustification = "";
            });

            ret.sort((a: submission, b: submission) =>
              Date.parse(a.Date) > Date.parse(b.Date) ? -1 : 1
            );

            setLeanArr(ret);
          });
        } else {
          let ret: submission[] = [...ret1];
          ret.forEach((el, ind) => {
            el.BeforePic = "";
            el.AfterPic = "";
            el.PicChange = 0;
            if (!_.isString(el.ApproverJustification))
              el.ApproverJustification = "";
          });

          ret.sort((a: submission, b: submission) =>
            Date.parse(a.Date) > Date.parse(b.Date) ? -1 : 1
          );

          setLeanArr(ret);
        }
      });

      getUserBigFinds(name, employee.badgeNum, startDate, endDate).then(
        (ret1: any) => {
          if (employee.badgeNum !== employee.hidNum) {
            getUserBigFinds(name, employee.hidNum, startDate, endDate).then(
              (ret2: any) => {
                let ret: any = [...ret1, ...ret2];
                ret.forEach((el: any, ind: number) => {
                  el.BeforePic = "";
                  el.AfterPic = "";
                  el.PicChange = 0;
                  if (!_.isString(el.ApproverJustification))
                    el.ApproverJustification = "";
                });

                ret.sort((a: any, b: any) =>
                  Date.parse(a.Date) > Date.parse(b.Date) ? -1 : 1
                );

                setBigFindArr(ret);
              }
            );
          } else {
            let ret: any = [...ret1];
            ret.forEach((el: any, ind: number) => {
              el.BeforePic = "";
              el.AfterPic = "";
              el.PicChange = 0;
              if (!_.isString(el.ApproverJustification))
                el.ApproverJustification = "";
            });

            ret.sort((a: any, b: any) =>
              Date.parse(a.Date) > Date.parse(b.Date) ? -1 : 1
            );

            setBigFindArr(ret);
          }
        }
      );
    }
  }, [loginSuccess, selectedStartDate, selectedEndDate, employee]);

  useEffect(() => {
    if (tab === 0) {
      setActiveArr(impactArr);
      setActiveCols(impactCols);
    } else if (tab === 1) {
      setActiveArr(leanArr);
      setActiveCols(leanCols);
    } else {
      setActiveArr(bigFindArr);
      setActiveCols(bigFindCols);
    }
  }, [tab, impactArr, leanArr, impactCols, leanCols, bigFindArr, bigFindCols]);

  useEffect(() => {
    if (selectedSubmission.UID === "" || !fetchPics) return;

    getPics(selectedSubmission.UID).then((ret) => {
      setSelectedSubmission((sub) => ({
        ...sub,
        BeforePic: ret[0].BeforePic,
        AfterPic: ret[0].AfterPic,
      }));
      setDisplayDetailsModal(true);
    });

    setFetchPics(false);
  }, [selectedSubmission, fetchPics]);

  const fetchBigFindPicture = useCallback(
    (bigFind: BigFind) => {
      if (bigFind.UID !== "") {
        getBigFindPic(bigFind.UID).then((ret) => {
          setSelectedBigFind((bf) => ({
            ...bf,
            Picture: ret[0].Picture,
          }));
          setDisplayBigFindDetailsModal(true);
        });
      }
    },
    [setSelectedBigFind, setDisplayBigFindDetailsModal]
  );

  function renderDate(params: GridRenderCellParams) {
    let date = new Date(params.row.Date);

    return <Typography>{date.toDateString()}</Typography>;
  }

  function renderChange(params: GridRenderCellParams) {
    return (
      <div style={{ paddingBlock: "1vh" }}>
        <Typography>{params.row.Change}</Typography>
      </div>
    );
  }

  function renderSummary(params: GridRenderCellParams) {
    return (
      <div style={{ paddingBlock: "1vh" }}>
        <Typography>{params.row.Summary}</Typography>
      </div>
    );
  }

  const renderDetails = useCallback(
    (params: GridRenderCellParams) => {
      return (
        <Grid container justifyContent={"center"} sx={{ paddingY: "1vh" }}>
          <Grid item xs={1}>
            <IconButton
              onClick={() => {
                //debugger;
                if (params.row.Type) {
                  setSelectedSubmission(params.row);
                  setFetchPics(true);
                  //fetchPictures();
                  //setDisplayDetailsModal(true);
                } else {
                  setSelectedBigFind(params.row);
                  fetchBigFindPicture(params.row);
                }
              }}
            >
              <InfoIcon sx={{ color: "black" }} />
            </IconButton>
          </Grid>
        </Grid>
      );
    },
    [
      setSelectedSubmission,
      setFetchPics,
      setSelectedBigFind,
      fetchBigFindPicture,
    ]
  );

  // function renderDetails(params: GridRenderCellParams) {
  //   return (
  //     <Grid container justifyContent={"center"} sx={{ paddingY: "1vh" }}>
  //       <Grid item xs={1}>
  //         <IconButton
  //           onClick={() => {
  //             //debugger;
  //             if (params.row.Type) {
  //               setSelectedSubmission(params.row);
  //               setFetchPics(true);
  //               //fetchPictures();
  //               //setDisplayDetailsModal(true);
  //             } else {
  //               setSelectedBigFind(params.row);
  //               fetchBigFindPicture(params.row);
  //             }
  //           }}
  //         >
  //           <InfoIcon sx={{ color: "black" }} />
  //         </IconButton>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  function renderStatus(params: GridRenderCellParams) {
    let status = params.row.Status;
    let s: Status = statuses[status as keyof typeof statuses];
    let bgColor: any = s.color;
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Card
          sx={{
            paddingY: 2,
            textAlign: "center",
            backgroundColor: bgColor,
            height: "100%",
          }}
        >
          <Typography>{params.row.Status}</Typography>
        </Card>
      </div>
    );
  }

  useEffect(() => {
    if (employee.lastName !== "" && employee.badgeNum !== 0)
      setLoginSuccess(true);
  }, [employee]);

  useEffect(() => {
    if (isMobile) {
      setImpactCols([
        { field: "Date", headerName: "Date", flex: 1, renderCell: renderDate },
        {
          field: "Status",
          headerName: "Status",
          flex: 1,
          renderCell: renderStatus,
        },
        {
          field: "Details",
          headerName: "Details",
          flex: 0.5,
          renderCell: renderDetails,
        },
      ]);
      setLeanCols([
        { field: "Date", headerName: "Date", flex: 1, renderCell: renderDate },
        {
          field: "Status",
          headerName: "Status",
          flex: 1,
          renderCell: renderStatus,
        },
        {
          field: "Details",
          headerName: "Details",
          flex: 0.5,
          renderCell: renderDetails,
        },
      ]);
      setBigFindCols([
        { field: "Date", headerName: "Date", flex: 1, renderCell: renderDate },
        {
          field: "Status",
          headerName: "Status",
          flex: 1,
          renderCell: renderStatus,
        },
        {
          field: "Details",
          headerName: "Details",
          flex: 0.5,
          renderCell: renderDetails,
        },
      ]);
    } else {
      setImpactCols([
        { field: "Date", headerName: "Date", flex: 1, renderCell: renderDate },
        { field: "Name", headerName: "Name", flex: 0.85 },
        { field: "Dept", headerName: "Dept", flex: 1 },
        { field: "Benefit", headerName: "Benefit", flex: 0.75 },
        {
          field: "Change",
          headerName: "Change",
          flex: 2.25,
          renderCell: renderChange,
        },
        {
          field: "Status",
          headerName: "Status",
          flex: 1,
          renderCell: renderStatus,
        },
        {
          field: "Details",
          headerName: "Details",
          flex: 0.5,
          renderCell: renderDetails,
        },
      ]);
      setLeanCols([
        { field: "Date", headerName: "Date", flex: 1, renderCell: renderDate },
        { field: "Name", headerName: "Name", flex: 0.85 },
        { field: "Dept", headerName: "Dept", flex: 1 },
        { field: "Benefit", headerName: "Benefit", flex: 0.75 },
        {
          field: "Change",
          headerName: "Change",
          flex: 2.25,
          renderCell: renderChange,
        },
        {
          field: "Status",
          headerName: "Status",
          flex: 1,
          renderCell: renderStatus,
        },
        {
          field: "Details",
          headerName: "Details",
          flex: 0.5,
          renderCell: renderDetails,
        },
      ]);
      setBigFindCols([
        { field: "Date", headerName: "Date", flex: 1, renderCell: renderDate },
        { field: "Name", headerName: "Name", flex: 0.85 },
        { field: "Dept", headerName: "Dept", flex: 1 },
        {
          field: "Summary",
          headerName: "Summary",
          flex: 2.25,
          renderCell: renderSummary,
        },
        {
          field: "Status",
          headerName: "Status",
          flex: 1,
          renderCell: renderStatus,
        },
        {
          field: "Details",
          headerName: "Details",
          flex: 0.5,
          renderCell: renderDetails,
        },
      ]);
    }
  }, [isMobile, renderDetails]);

  return (
    <div style={{ maxWidth: "95vw", margin: "auto" }}>
      {loginSuccess && (
        <>
          <Grid
            container
            justifyContent={"flex-start"}
            sx={{ paddingY: "3vh" }}
          >
            <Grid item xs={12} xl={12}>
              <Typography sx={{ fontSize: 20, paddingBottom: "2vh" }}>
                <strong>Getting Records Between:</strong>
              </Typography>
            </Grid>
            <Grid item xs={6} xl={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="Start Date"
                  value={selectedStartDate}
                  onChange={(newValue: any) => {
                    setSelectedStartDate(newValue);
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} xl={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="End Date"
                  value={selectedEndDate}
                  onChange={(newValue: any) => {
                    setSelectedEndDate(newValue);
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xl={12} />

            <Grid
              item
              xs={12}
              xl={4}
              textAlign="center"
              sx={{ marginTop: "4vh" }}
            >
              <Card sx={{ paddingY: "2vh" }} elevation={4}>
                {tab === 0 && (
                  <>
                    <Typography sx={{ fontSize: 25 }}>
                      <strong>{`${impactArr.length}`}</strong>
                    </Typography>
                    <Typography>
                      Impacts Submitted Within This Time Range
                    </Typography>
                  </>
                )}
                {tab === 1 && (
                  <>
                    <Typography sx={{ fontSize: 25 }}>
                      <strong>{`${leanArr.length}`}</strong>
                    </Typography>
                    <Typography>
                      Leans Submitted Within This Time Range
                    </Typography>
                  </>
                )}
                {tab === 2 && (
                  <>
                    <Typography sx={{ fontSize: 25 }}>
                      <strong>{`${leanArr.length}`}</strong>
                    </Typography>
                    <Typography>
                      Big Finds Submitted Within This Time Range
                    </Typography>
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
          <Tabs value={tab} onChange={handleChangeTab}>
            <Tab label="Drive Impacts" />
            <Tab label="Drive Leans" />
            <Tab label="Big Finds" />
          </Tabs>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "10px" }}
          >
            <Card sx={{ width: "100%", paddingBottom: "5vh" }} elevation={4}>
              <CardContent>
                <TableWithSearch
                  height={"65vh"}
                  DataArr={activeArr}
                  TableCols={activeCols}
                  selModel={selModel}
                  setSelModel={setSelModel}
                />
              </CardContent>
            </Card>
          </Grid>
        </>
      )}

      <GetInfoModal
        pageWidth={pageWidth}
        userInfo={userInfo}
        displayModal={displayGetInfoModal}
        setDisplayModal={setDisplayModal}
        setEmployee={setEmployee}
      />

      <DetailsModal
        pageWidth={pageWidth}
        displayModal={displayDetailsModal}
        setDisplayModal={setDisplayDetailsModal}
        submission={selectedSubmission}
      />

      <BigFindDetailsModal
        pageWidth={pageWidth}
        displayModal={displayBigFindDetailsModal}
        setDisplayModal={setDisplayBigFindDetailsModal}
        bigFind={selectedBigFind}
      />
    </div>
  );
}

export default MySubmissions;
