import { fetchCoreAPI, fetchAzureGet, fetchAzurePost } from './ApiHelpers';
// TODO: Change to the path name of the api backend, usually the "<pathname>-api"
export const apiPathNameIIS = 'test-api/';


export async function getAPICoreUserInfo() {
    const apiEndpoint = 'userinfoall';
    const queryStrings = {};
    return fetchCoreAPI(apiEndpoint, queryStrings);
}

export async function getDepartments() {
    const url = 'https://dai-wcm.azurewebsites.net/api/getDepartments?code=-qorF5qJSKMjRN6Q8KqU3Caxw43zEcFAZqNPQDoqFOS7AzFuELr4Kg==&';
    const queryStrings = {};
    return fetchAzureGet(url, queryStrings);
}

export async function getPics(uid: string) {
    const url = 'https://dai-wcm.azurewebsites.net/api/getpics?code=VBy7hsEGAhHyvnGziSPcpqzCyqXGrl2k-qVf_PqexyFdAzFuLcsFWw==&';
    const queryStrings = {UID: uid};
    return fetchAzureGet(url, queryStrings);
}

export async function getBigFindPic(uid: string) {
    const url = 'https://dai-wcm.azurewebsites.net/api/getbigfindpic?code=WTuF5QXPO2_yJqS56usHZdpH_FwsCMFKe57_9LowjPQfAzFuNeGr8w==&';
    const queryStrings = {UID: uid};
    return fetchAzureGet(url, queryStrings);
}


export async function getUserSubmissions(name: string, badgeNumber: number, startDate: string, endDate: string, type: string) {
    const url = 'https://dai-wcm.azurewebsites.net/api/getusersubmissions?code=o1sx-EOGiD_vAjVHzMPL8s6yrBrsTqLHiYPP2TY6ED41AzFuSqBCWQ==&';
    const queryStrings = {Name: name, BadgeNumber: badgeNumber, StartDate: startDate, EndDate: endDate, Type: type};
    return fetchAzureGet(url, queryStrings);
}

export async function getUserBigFinds(name: string, badgeNumber: number, startDate: string, endDate: string) {
    const url = 'https://dai-wcm.azurewebsites.net/api/getuserbigfinds?code=pC-4SqEmiyzkPesAXOXzQuGQoqG9e9FrShf8IibjJSO0AzFuVS7Eig==&';
    const queryStrings = {Name: name, BadgeNumber: badgeNumber, StartDate: startDate, EndDate: endDate};
    return fetchAzureGet(url, queryStrings);
}

export async function postSubmission(postBody: any) {
    const url = 'https://dai-wcm.azurewebsites.net/api/postsubmission?code=o4ftfBfB2ve_7zMirm2Se2Oo_CrK5dkdmqUpBWuAV36YAzFu3lfD0Q==&';
    return fetchAzurePost(url, postBody);
}

export async function postBigFind(postBody: any) {
    const url = 'https://dai-wcm.azurewebsites.net/api/postbigfind?code=bQEXNT15RrdnCitekrNphf0XeHAJ-a0wsvyJFmWmvezSAzFur2yBJg==&';
    return fetchAzurePost(url, postBody);
}

export async function checkBadgeNum(badgeNumber: number, lastName: string) {
    const url = 'https://dai-wcm.azurewebsites.net/api/checkbadgenum?code=pBD_ruWtPDlpXiP1atd71vP88RE7fjo-54wTA5LyqWniAzFuXzivFA==&';
    const queryStrings = {BadgeNumber: badgeNumber, LastName: lastName};
    return fetchAzureGet(url, queryStrings);
}

export async function sendEmail(email: string) {
    const url = 'https://dai-wcm.azurewebsites.net/api/sendemail?code=njPVaiIqQGYDvVWoc9SF-WW1ncIBLwPuxDH0o96IB1GcAzFu38NW-A==&';
    const queryStrings = {Email: email};
    return fetchAzureGet(url, queryStrings);
}
