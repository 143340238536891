import Typography from '@mui/material/Typography';

function PageHeader({ title, subtitle }: { title: string; subtitle: string; }) {
    return (
        <>
            <Typography sx={{paddingLeft: 2}} variant='h4' component='h2'>
                <strong>{title}</strong>
            </Typography>
            <Typography sx={{paddingLeft: 2, paddingBottom: 2 }} fontStyle='italic' variant='body1' component='h2'>
                {subtitle}
            </Typography>
        </>
    );
}

export default PageHeader;
