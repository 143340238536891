import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/header/Header';
import About from './views/About';
import UserInfo from './ts/interfaces/app/userinfo.interface';
import { ThemeProvider, createTheme } from '@mui/material/styles';

//import { getAPICoreUserInfo } from './services/Api';
//import AccessDenied from './views/AccessDenied';
//import LoadingCredentials from './views/LoadingCredentials';
import appTheme from './constants/theme.json';
//import ADGroupWhitelist from './constants/ADGroupWhitelist.json';
import AppBreadcrumbs from './components/general/AppBreadcrumbs';
import Main from './views/Main';
import Lean from './views/Lean';
import Impact from './views/Impact';
import BigFinds from './views/BigFInds';
import MySubmissions from './views/MySubmissons';

const themeOptions: { palette: any } = appTheme;
const globalMaxWidth = 1600;

function App() {
    const [pageWidth, setPageWidth] = React.useState<number>(Math.min(window.innerWidth,globalMaxWidth));
    const [isMobile, setIsMobile] = React.useState(false);

    function handleWindowSizeChange() {
        setPageWidth(Math.min(window.innerWidth,globalMaxWidth));
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        if(window.innerWidth < 1600) setIsMobile(true);
        else setIsMobile(false);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    React.useEffect(() => {
        if(pageWidth < 1600) setIsMobile(true);
        else setIsMobile(false);
    }, [pageWidth]);

    const theme = createTheme(themeOptions);

    const [userInfo] = React.useState<UserInfo>({
        originalUsername: '',
        normalizedUsername: '',
        strippedUsername: '',
        userDomain: '',
        ADGroups: [],
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header pageWidth={pageWidth} /*userInfo={userInfo}*/ isMobile={isMobile} />
            <AppBreadcrumbs />
            <Routes>
                <>
                    <Route path='/' element={<Main pageWidth={pageWidth} userInfo={userInfo} isMobile={isMobile}/>} />
                    <Route path='/about' element={<About pageWidth={pageWidth} />} />
                    <Route path='/impact' element={<Impact pageWidth={pageWidth} userInfo={userInfo} />} />
                    <Route path='/lean' element={<Lean pageWidth={pageWidth} userInfo={userInfo} />} />
                    <Route path='/bigfinds' element={<BigFinds pageWidth={pageWidth} userInfo={userInfo} />} />
                    <Route path='/mysubmissions' element={<MySubmissions pageWidth={pageWidth} userInfo={userInfo} isMobile={isMobile}/>} />
                </>
                {/* {loadingCredentials && 
                    <>
                        <Route path='/' element={<LoadingCredentials pageWidth={pageWidth} userInfo={userInfo} />} />
                    </>
                }
                {(!loadingCredentials && ADGroupWhitelist.filter(group => userInfo.ADGroups.includes(group)).length > 0) ? (
                    <>
                        <Route path='/' element={<Main pageWidth={pageWidth} userInfo={userInfo} />} />
                        <Route path='/about' element={<About pageWidth={pageWidth}/>} />
                        <Route path='/impact' element={<Impact pageWidth={pageWidth} userInfo={userInfo} />} />
                        <Route path='/lean' element={<Lean pageWidth={pageWidth} userInfo={userInfo} />} />

                    </>
                ) : (
                    <>
                        <Route path='/' element={<AccessDenied pageWidth={pageWidth} userInfo={userInfo} />} />
                    </>
                )} */}
            </Routes>
        </ThemeProvider>
    );
}

export default App;
