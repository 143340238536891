import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import PageHeader from '../components/general/PageHeader';
import CardHeader from '../components/general/CardHeader';

function Home({ pageWidth }: { pageWidth: number; }) {


    return (
        <div style={{ maxWidth: pageWidth, margin: 'auto' }}>
            <PageHeader title='Page Header' subtitle='Subtitle text...' />
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: '10px' }}
            >
                <Card sx={{ width: '98%' }}>
                    <CardHeader title='Card Title' subtitle='Card subtitle' />
                </Card>
            </Grid>
        </div>
    );
}

export default Home;
