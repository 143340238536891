// all external packages brought
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';

// ts interfaces
import { CardMedia, IconButton, Modal, Typography } from '@mui/material';

import statuses from '../../constants/statuses.json';
import BigFind from '../../ts/interfaces/MySubmissions/bigfind.interface';

function BigFindDetailsModal({
    pageWidth,
    displayModal,
    setDisplayModal,
    bigFind,
}: {
    pageWidth: number;
    displayModal: boolean;
    setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
    bigFind: BigFind;
}) {
    const [dateStr, setDateStr] = useState<string>('');

    useEffect(() => {
        let date = new Date(bigFind.Date);
        setDateStr(date.toDateString());
    }, [bigFind]);

    return (
        <div style={{ maxWidth: pageWidth, margin: 'auto' }}>
            <Modal open={displayModal} sx={{ height: 'auto', marginY: '5vh', marginX: '5vw', paddingBottom: '5vw', overflow: 'scroll' }}>
                <Card sx={{ paddingX: '2vw' }}>
                    <Grid container justifyContent={'flex-end'}>
                        <IconButton
                            onClick={() => {
                                setDisplayModal(false);
                            }}
                            sx={{ marginRight: '-2vw' }}
                        >
                            <CloseIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                    </Grid>

                    <Grid container justifyContent={'center'} spacing={1}>
                        <Grid item xs={12}>
                            <Typography textAlign={'center'} sx={{ fontSize: 25, marginBottom: '5vh' }}>
                                <strong>Submission Details:</strong>
                            </Typography>
                        </Grid>

                        {bigFind.Picture !== '' && (
                            <Grid item xs={12} justifyContent={'center'}>
                                <Card sx={{ width: 'fit-content', textAlign: 'center', marginTop: '2vh', /*marginInline: 'auto'*/ }}>
                                    <Typography sx={{ fontSize: 25 }}>
                                        <strong>Submission Picture</strong>
                                    </Typography>
                                    <CardMedia component='img' image={`data:image/png;base64,${bigFind.Picture}`} />
                                </Card>
                            </Grid>
                        )}

                        <Grid item xs={12} sx={{ textAlign: 'flex-start', marginTop: '5vh' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Date: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${dateStr}`}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Name: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${bigFind.Name}`}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Department: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${bigFind.Dept}`}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Shift: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${bigFind.Shift}`}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Supervisor: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${bigFind.Supervisor}`}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Summary: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${bigFind.Summary}`}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Status: </strong>
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>{`${bigFind.Status}`}</Typography>
                        </Grid>

                        {bigFind.Status === statuses.Denied.text && (
                            <Grid item xs={12} sx={{ textAlign: 'flex-start' }}>
                                <Typography sx={{ fontSize: 25 }}>
                                    <strong>Denial justification: </strong>
                                </Typography>
                                <Typography sx={{ fontSize: 20 }}>{`${bigFind.DenialJustification}`}</Typography>
                            </Grid>
                        )}

                        <Grid item xs={12} sx={{ paddingBottom: '5vh' }} />
                    </Grid>
                </Card>
            </Modal>
        </div>
    );
}

export default BigFindDetailsModal;
