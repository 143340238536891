// all external packages brought
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Resizer from 'react-image-file-resizer';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { styled } from '@mui/material/styles';

import PageHeader from '../components/general/PageHeader';
import CardHeader from '../components/general/CardHeader';

// ts interfaces
import UserInfo from '../ts/interfaces/app/userinfo.interface';
import { CardActions, CardMedia, IconButton, Modal, Select, Typography } from '@mui/material';

import { checkBadgeNum, getDepartments, postSubmission } from '../services/Api';

const strLenLim = 1000;

const Input = styled('input')({
    display: 'none',
});

const topics = ['Cost', 'Delivery', 'Environmental', 'Ergonomics', 'Morale', 'Quality', 'Safety', 'Sustainability', '5S'];

const shifts = ['1st', '2nd', '3rd'];

const accFileTypes = ['png', 'jpeg', 'jpg', 'svg', 'bmp'];

function Impact({ pageWidth, userInfo }: { pageWidth: number; userInfo: UserInfo }) {
    const [name, setName] = useState<string>('');
    const [badgeNum, setBadgeNum] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [selectedDept, setSelectedDept] = useState<string>('');
    const [shift, setShift] = useState<string>('');
    const [change, setChange] = useState<string>('');
    const [benefit, setBenefit] = useState<string>('');
    const [picBase64, setPicBase64] = useState<string>('');
    const [modalWidth, setModalWidth] = useState<string>('20vw');
    const [modalHeight, setModalHeight] = useState<string>('10vh');
    const [marginHeight, setMarginHeight] = useState<string>('45vh');
    const [marginWidth, setMarginWidth] = useState<string>('40vw');
    const [deptArr, setDeptArr] = useState<string[]>([]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [validPic, setValidPic] = useState(false);
    const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
    const [displayErrorModal, setDisplayErrorModal] = useState(false);

    function clear() {
        setChange('');
        setBenefit('');
        setPicBase64('');
        setValidPic(false);
    }

    const handleTextFieldChange = (val: string, setFunc: React.Dispatch<any>) => {
        if (val.length <= strLenLim) setFunc(val);
    };

    async function handleSubmit() {
        setDisableSubmit(true);

        let lastname = '';
        let temp = name.split(' ');
        if (temp.length >= 2) {
            let str = temp.pop();
            if (str) lastname = str;
        } else {
            alert('First and last name needed!!!');
            return;
        }

        let verified = false;

        await checkBadgeNum(parseInt(badgeNum), lastname).then((ret) => {
            if (!ret.Match) {
                alert('Name does not match badge number!!!');
            } else verified = true;
        });

        if (verified) {
            let json = {
                Type: 'Impact',
                Name: name,
                BadgeNumber: badgeNum,
                Email: email,
                Dept: selectedDept,
                Shift: shift,
                Benefit: benefit,
                Change: change,
                BeforePic: picBase64,
                AfterPic: '',
            };

            postSubmission(json).then((ret) => {
                if (ret.length === 1 && Object.keys(ret[0]).includes('UID')) {
                    setDisplaySuccessModal(true);
                    clear();
                } else {
                    setDisplayErrorModal(true);
                }
            });
        }
    }

    const resizeFile = async (file: any) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                900,
                900,
                'JPEG',
                100,
                0,
                (uri: any) => {
                    resolve(uri);
                },
                'base64'
            );
        });

    useEffect(() => {
        getDepartments().then((ret) => {
            let arr: string[] = [];
            ret.forEach((el: any) => {
                arr.push(el.Dept);
            });

            setDeptArr(arr);
        });
    }, []);

    useEffect(() => {
        let validInputs = true;
        if (selectedDept === '') validInputs = false;
        else if (name === '') validInputs = false;
        else if (badgeNum === '') validInputs = false;
        else if (benefit === '') validInputs = false;
        else if (change === '') validInputs = false;
        else if (shift === '') validInputs = false;

        if (validInputs) setDisableSubmit(false);
        else setDisableSubmit(true);
    }, [selectedDept, name, email, change, benefit, badgeNum, shift]);

    useEffect(() => {
        if (displaySuccessModal) {
            setTimeout(() => setDisplaySuccessModal(false), 3000);
        }
    }, [displaySuccessModal]);

    useEffect(() => {
        if (displayErrorModal) {
            setTimeout(() => setDisplayErrorModal(false), 3000);
        }
    }, [displayErrorModal]);

    useEffect(() => {
        let w=0, h=0;
        if(window.innerWidth >= 1000){
            w = 20;
            h = 15;
        }
        else {
            w=75;
            h=15;
        }

        setModalHeight(h.toString() + 'vh');
        setModalWidth(w.toString() + 'vw');
        setMarginHeight(((100 - h)/2).toString() + 'vh');
        setMarginWidth(((100 - w)/2).toString() + 'vw');

    }, []);

    return (
        <div style={{ maxWidth: pageWidth, margin: 'auto' }}>
            <PageHeader title='Drive Impact' subtitle='Employee Suggestion Program' />
            <Card elevation={10} sx={{ padding: 1, marginY: '2vh', justifyItems: 'center' }}>
                <Grid container justifyContent='center' style={{ marginTop: '3vh', marginBottom: '3vh', padding: 10 }}>
                    <Grid item xs={11} xl={5} sx={{ marginBottom: '5vh' }}>
                        <Card elevation={2} sx={{ paddingY: 1, justifyItems: 'center' }}>
                            <Grid container justifyContent='center'>
                                <Grid item xs={11} xl={5} textAlign='center'>
                                    <CardHeader title='Picture (optional)' subtitle='' />
                                </Grid>
                            </Grid>
                            {!validPic && (
                                <Grid container justifyContent='center'>
                                    <Grid item xs={3} xl={1} sx={{ paddingLeft: 1 }}>
                                        <label htmlFor='before-picture'>
                                            <Input
                                                accept='image/png, image/jpeg, image/jpg, image/svg, image/bmp'
                                                id='before-picture'
                                                type='file'
                                                style={{ marginTop: 15, marginLeft: 15 }}
                                                onChange={(event) => {
                                                    if (event.target.files) {
                                                        var file = event.target.files[0];
                                                        let tarr: string[] = file.name.split('.');
                                                        let ext: string = 'placeholder';
                                                        if (tarr) ext = tarr[tarr.length - 1].toLowerCase();
                                                        if (accFileTypes.includes(ext)) {
                                                            //setPic(file);
                                                            resizeFile(file).then((data: any) => {
                                                                setPicBase64(data.split(',')[1]);
                                                            });
                                                            setValidPic(true);
                                                        } else alert('File type not accepted!!! Must be a basic image file type (ie. jpeg)');
                                                    }
                                                }}
                                            />
                                            <IconButton color='primary' aria-label='upload before picture' component='span'>
                                                <AddBoxIcon sx={{ fontSize: 40 }} />
                                            </IconButton>
                                        </label>
                                    </Grid>
                                </Grid>
                            )}

                            {validPic && <CardMedia sx={{ height: '900px' }} component='img' image={`data:image/png;base64,${picBase64}`} />}
                        </Card>
                    </Grid>

                    <Grid item xs={12} />

                    <Grid item xs={11} xl={3} sx={{ marginY: '3vh' }}>
                        <>
                            <Typography sx={{ fontSize: 20 }}>
                                <strong>{'Name (First and Last)'}:</strong>
                            </Typography>
                            <TextField id='name-textfield' value={name} onChange={(e) => handleTextFieldChange(e.target.value, setName)} fullWidth />
                        </>
                    </Grid>

                    <Grid item xl={1} />

                    <Grid item xs={11} xl={3} sx={{ marginY: '3vh' }}>
                        <>
                            <Typography sx={{ fontSize: 20 }}>
                                <strong>Badge Number:</strong>
                            </Typography>
                            <TextField
                                id='badgenum-textfield'
                                type={'number'}
                                value={badgeNum}
                                onChange={(e) => handleTextFieldChange(e.target.value, setBadgeNum)}
                                fullWidth
                            />
                        </>
                    </Grid>

                    <Grid item xl={1} />

                    <Grid item xs={11} xl={3} sx={{ marginY: '3vh' }}>
                        <>
                            <Typography sx={{ fontSize: 20 }}>
                                <strong>Email:</strong>
                            </Typography>
                            <TextField
                                id='email-textfield'
                                value={email}
                                onChange={(e) => handleTextFieldChange(e.target.value, setEmail)}
                                fullWidth
                            />
                        </>
                    </Grid>

                    <Grid item xs={11} xl={3} sx={{ marginY: '3vh' }}>
                        <Grid container justifyContent='center'>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20 }}>
                                    <strong>My Department:</strong>
                                </Typography>
                                <Select
                                    id='department-select'
                                    value={selectedDept}
                                    onChange={(e) => handleTextFieldChange(e.target.value, setSelectedDept)}
                                    fullWidth
                                >
                                    {deptArr.map((el: any, index: number) => (
                                        <MenuItem key={index} value={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xl={1} />

                    <Grid item xs={11} xl={3} sx={{ marginY: '3vh' }}>
                        <>
                            <Typography sx={{ fontSize: 20 }}>
                                <strong>Shift:</strong>
                            </Typography>
                            <Select id='shift-select' value={shift} onChange={(e) => handleTextFieldChange(e.target.value, setShift)} fullWidth>
                                {shifts.map((el: any, index: number) => (
                                    <MenuItem key={index} value={el}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                    </Grid>

                    <Grid item xl={1} />

                    <Grid item xs={11} xl={3} sx={{ marginY: '3vh' }}>
                        <>
                            <Typography sx={{ fontSize: 20 }}>
                                <strong>Benefit:</strong>
                            </Typography>
                            <Select id='benefit-select' value={benefit} onChange={(e) => handleTextFieldChange(e.target.value, setBenefit)} fullWidth>
                                {topics.map((el: any, index: number) => (
                                    <MenuItem key={index} value={el}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                    </Grid>

                    <Grid item xl={4} />

                    <Grid item xs={11} xl={11} sx={{ marginY: '3vh' }}>
                        <>
                            <Typography sx={{ fontSize: 20 }}>
                                <strong>{'Idea: Please give details on what to improve: (Please include area and/or station)'}</strong>
                            </Typography>
                            <TextField
                                id='change-textfield'
                                value={change}
                                onChange={(e) => handleTextFieldChange(e.target.value, setChange)}
                                fullWidth
                                multiline
                                rows={4}
                                inputProps={{ maxLength: strLenLim }}
                            />
                        </>
                    </Grid>
                    <Grid item xs={11} xl={11}>
                        <Grid container justifyContent='flex-end'>
                            <Grid item xs={6} xl={2} sx={{ textAlign: 'right' }}>
                                <Typography sx={{}}>{`${change.length}/${strLenLim} Characters`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <CardActions sx={{ marginTop: 10 }}>
                    <Grid container justifyContent='center'>
                        <Grid xl={9.5} />
                        <Grid item xs={11} xl={2}>
                            <Button variant='contained' onClick={handleSubmit} sx={{ fontSize: 20 }} disabled={disableSubmit} fullWidth>
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xl={0.5} />
                    </Grid>
                </CardActions>
            </Card>

            <Modal open={displaySuccessModal}>
                <Card sx={{ width: modalWidth, height: modalHeight, bgcolor: '#5DBB63', marginY: marginHeight, marginX: marginWidth }}>
                    <Grid container justifyContent='flex-end'>
                        <Grid item xs={12} textAlign={'center'} sx={{ marginTop: '5vh' }}>
                            <Typography sx={{ fontSize: 25, color: '#FFFFFF' }}>
                                <strong>Submitted!!</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Modal>

            <Modal open={displayErrorModal}>
                <Card sx={{ width: modalWidth, height: modalHeight, bgcolor: '#ED4337', marginY: marginHeight, marginX: marginWidth }}>
                    <Grid container justifyContent='flex-end'>
                        <Grid item xs={12} textAlign={'center'} sx={{ marginTop: '2vh' }}>
                            <Typography sx={{ fontSize: 25, color: '#FFFFFF' }}>
                                <strong>Error!! Submission Not Accepted!!</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Modal>
        </div>
    );
}

export default Impact;
