import { apiPathNameIIS } from './Api';

const isLocalPath = (currentURLPath: string) => {
    return currentURLPath.includes('localhost');
};

const generateQueryStrings = (url: string, queryStrings: any) => {
    if (!queryStrings) {
        return '';
    }
    
    let queryStringsForURLPath = '?';
    if(url.endsWith('&')) queryStringsForURLPath = '';
    
    for (const [key, value] of Object.entries(queryStrings)) {
        queryStringsForURLPath += `${key}=${value}&`;
    }
    return queryStringsForURLPath;
};

export const fetchDataGet = async (apiEndpoint: string, queryStrings: any) => {
    let currentURLProtocol = window.location.protocol;
    let currentURLPath = window.location.host;
    currentURLPath += currentURLPath.endsWith('/') ? '' : '/';
    const queryStringsForURLPath = generateQueryStrings(apiEndpoint, queryStrings);

    let response;
    if (isLocalPath(currentURLPath)) {
        response = await fetch(`${currentURLProtocol}//localhost:3001/${apiEndpoint}${queryStringsForURLPath}`);
    } else {
        response = await fetch(`${currentURLProtocol}//${currentURLPath}${apiPathNameIIS}${apiEndpoint}${queryStringsForURLPath}`);
    }
    return await response.json();
};

export const fetchDataPost = async (apiEndpoint: string, body: any) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    };

    let currentURLProtocol = window.location.protocol;
    let currentURLPath = window.location.host;
    currentURLPath += currentURLPath.endsWith('/') ? '' : '/';

    let response;
    if (isLocalPath(currentURLPath)) {
        response = await fetch(`${currentURLProtocol}//localhost:3001/${apiEndpoint}`, requestOptions);
    } else {
        response = await fetch(`${currentURLProtocol}//${currentURLPath}${apiPathNameIIS}${apiEndpoint}`, requestOptions);
    }
    return await response.json();
};

export const fetchCoreAPI: (apiEndpoint: string, queryStrings: any) => any = async (coreEndpoint, queryStrings) => {
    let currentURLProtocol = window.location.protocol;
    let currentURLPath = window.location.host;
    let queryStringsForURLPath;

    let response;
    if (isLocalPath(currentURLPath)) {
        queryStringsForURLPath = generateQueryStrings(coreEndpoint, { localdev: 'true', ...queryStrings });
        response = await fetch(`${currentURLProtocol}//localhost:3001/daicore${queryStringsForURLPath}coreendpoint=${coreEndpoint}`);
    } else {
        queryStringsForURLPath = generateQueryStrings(coreEndpoint, { localdev: 'false', ...queryStrings });
        response = await fetch(`${currentURLProtocol}//dai.magna.global/daicore/${coreEndpoint}${queryStringsForURLPath}`);
    }
    return await response.json();
};

export const fetchAzureGet = async (url: string, queryStrings: any) => {

    const queryStringsForURLPath = generateQueryStrings(url, queryStrings);

    let response = await fetch(`${url}${queryStringsForURLPath}`);
    return response.json();
};

export const fetchAzurePost = async (url: string, body: any) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    };

    let response = await fetch(url,requestOptions);

    return response.json();
};
