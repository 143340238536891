// all external packages brought
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import LeansImg from '../constants/Leans.png';
import ImpactsImg from '../constants/Impacts.png';
import BigFinds from '../constants/BigFinds.png';

// ts interfaces
import UserInfo from '../ts/interfaces/app/userinfo.interface';
import { CardActions, CardMedia, Divider, Typography } from '@mui/material';

function Main({ pageWidth, userInfo, isMobile }: { pageWidth: number; userInfo: UserInfo; isMobile: boolean }) {
    let navigate = useNavigate();

    return (
        <div style={{ maxWidth: pageWidth, margin: 'auto' }}>
            {/* <PageHeader title='Submit a Drive Impact or Drive Lean' subtitle=''/> */}

            <Grid container alignItems='center' justifyContent='center' style={{ marginTop: '3vh', marginBottom: '3vh' }}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>

                {isMobile && (
                    <Grid item xs={11.5} sx={{marginY: '5vh'}}>
                        <Button
                            fullWidth
                            sx={{fontSize: 25}}
                            variant='outlined'
                            onClick={() => {
                                navigate('/mysubmissions');
                            }}
                        >
                            My Submissions
                        </Button>
                    </Grid>
                )}

                <Grid item xs={11} textAlign='center'>
                    <Typography sx={{ alignSelf: 'center', fontSize: 35 }}>
                        <strong>Submit a Drive Impact or Drive Lean</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>

            <Grid container alignItems='center' justifyContent='center' style={{ marginTop: '10px', rowGap: '50px' }}>
                <Grid item xs={11} xl={5}>
                    <Card elevation={10} sx={{ padding: 1 }}>
                        <CardMedia component='img' image={ImpactsImg}/>
                        <CardContent>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Drive Impact</strong> - Get Assistance To Get It Done
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Grid container justifyContent='flex-end' style={{ marginTop: '10px' }}>
                                <Button
                                    variant='outlined'
                                    onClick={() => {
                                        navigate('/impact');
                                    }}
                                >
                                    Get Started
                                </Button>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xl={1} />

                <Grid item xs={11} xl={5}>
                    <Card elevation={10} sx={{ padding: 1 }}>
                        <CardMedia component='img' image={LeansImg}/>
                        <CardContent>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Drive Lean</strong> - Make A Difference Yourself
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Grid container justifyContent='flex-end' style={{ marginTop: '10px' }}>
                                <Button
                                    variant='outlined'
                                    onClick={() => {
                                        navigate('/lean');
                                    }}
                                >
                                    Get Started
                                </Button>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={11} xl={5}>
                    <Card elevation={10} sx={{ padding: 1 }}>
                        <CardMedia component='img' image={BigFinds}/>
                        <CardContent>
                            <Typography sx={{ fontSize: 25 }}>
                                <strong>Big Finds</strong> 
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Grid container justifyContent='flex-end' style={{ marginTop: '10px' }}>
                                <Button
                                    variant='outlined'
                                    onClick={() => {
                                        navigate('/bigfinds');
                                    }}
                                >
                                    Get Started
                                </Button>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

            <Grid container alignItems='center' justifyContent='center' style={{ marginTop: '10vh', marginBottom: '5vh' }}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={2} sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontSize: 35 }}>
                        <strong>FAQ</strong>
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>

            <Grid container alignItems='center' justifyContent='center' style={{ marginBottom: '10vh' }}>
                <Grid item xs={12} sx={{ marginBottom: '1vh' }}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>What are Drive Impacts?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Drive Impacts are things that employees cannot do by themselves and need some help from management in getting it
                                implemented. Examples of these include, but are not limited to: projects that require funding, projects that require
                                use of heavy machinery, and more.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Divider />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '1vh' }}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>What are Drive Leans?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Drive Leans are things that employees can do without any assistance from management. Examples of these include, but
                                are not limited to: 5S projects, elemination of safety hazards, and more.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '1vh' }}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>What are Big Finds?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Big Finds are aspects of parts that we produce that do not meet our standards. Examples of these include, but are not limited to:
                                splits, missing bolts, and more.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </div>
    );
}

export default Main;
