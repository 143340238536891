// all external packages brought
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CardHeader from '../general/CardHeader';

import ClearIcon from '@mui/icons-material/Clear';

// ts interfaces
import UserInfo from '../../ts/interfaces/app/userinfo.interface';
import Employee from '../../ts/interfaces/MySubmissions/employee.interface';
import { CardActions, CardContent, IconButton, Modal, Typography } from '@mui/material';

import { checkBadgeNum } from '../../services/Api';

function GetInfoModal({
    pageWidth,
    userInfo,
    displayModal,
    setDisplayModal,
    setEmployee,
}: {
    pageWidth: number;
    userInfo: UserInfo;
    displayModal: boolean;
    setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
    setEmployee: React.Dispatch<React.SetStateAction<Employee>>;
}) {
    const [lastName, setLastName] = useState<string>('');
    const [badgeNum, setBadgeNum] = useState<string>('');
    const [disableBtn, setDisableBtn] = useState<boolean>(true);
    const [submit, setSubmit] = useState<boolean>(false);

    useEffect(() => {
        if (lastName !== '' && badgeNum !== '') {
            setDisableBtn(false);
        }
    }, [ lastName, badgeNum]);

    useEffect(() => {
        if (submit) {
            checkBadgeNum(parseInt(badgeNum), lastName).then((ret) => {
                if(ret.Match){
                    setEmployee({
                        lastName: lastName,
                        badgeNum: parseInt(ret.BadgeNum),
                        hidNum: parseInt(ret.HIDNum)
                    });
                    setDisplayModal(false);
                } else {
                    alert('Name does not match badge number!!!');
                }
            });
            setSubmit(false);
        }
    }, [submit, badgeNum, lastName, setDisplayModal, setEmployee]);

    return (
        <div style={{ maxWidth: pageWidth, margin: 'auto' }}>
            <Modal open={displayModal} sx={{ height: 'auto', marginTop: '5vh', marginX: '5vw' }}>
                <Card sx={{ height: 'auto' }}>
                    <Grid container justifyContent={'flex-end'}>
                        <Grid item xs={11}>
                            <CardHeader title='Please enter your name and badge number:' subtitle='' />
                        </Grid>
                        <Grid item xs={0.45} />
                        <Grid item xs={0.55}>
                            <IconButton
                                onClick={() => {
                                    setDisplayModal(false);
                                }}
                            >
                                <ClearIcon sx={{ fontSize: 30, color: 'black' }} />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <CardContent>
                        <Grid container justifyContent='flex-start' style={{ marginTop: '3vh', marginBottom: '3vh' }}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, paddingTop: '2vh' }}>{'Last name:'}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={lastName}
                                    onChange={(el) => {
                                        setLastName(el.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, paddingTop: '2vh' }}>{'Badge number:'}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type={'number'}
                                    fullWidth
                                    value={badgeNum}
                                    onChange={(el) => {
                                        setBadgeNum(el.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container justifyContent={'flex-end'} spacing={1}>
                            <Grid item xs={5} xl={2}>
                                <Button
                                    variant='contained'
                                    sx={{ fontSize: 20, height: 55 }}
                                    disabled={disableBtn}
                                    fullWidth
                                    onClick={() => {
                                        setSubmit(true);
                                        setDisableBtn(true);
                                    }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Modal>
        </div>
    );
}

export default GetInfoModal;
